import React from "react";
import Header from './Header';



const About = () => (
    <React.Fragment>
    <Header />
  <div className='wrapper'>
    <p>Megan Mckissack is a frontend developer interested in open data,
       data visualization, and emerging technology.
    </p>
    </div>
    </React.Fragment>
);

export default About;